<template>
  <section class="section-how-it-works">
    <div class="how-it-works-wrapper">
      <div class="how-it-works-outer">
        <div class="how-it-works-inner">
          <div class="how-it-works">
            <div class="left-text-block-desktop">
              <img src="@/assets/images/arrow-to-right.svg" alt="Grower Plant" />
              <span>
                {{ $t('left_side_text') }}<br />
                <span class="underline">MIN</span>ING!<br />
                <span class="underline">TRAD</span>ING!<br />
              </span>
            </div>
            <div class="plant">
              <img class="plant-image" src="@/assets/images/plant.svg" alt="Grower Plant" />
            </div>
            <div class="right-text-block-desktop">
              <span>
                {{ $t('right_side_text') }}<br />
                <span class="underline">
                  GROWING!
                </span>
              </span>
              <img src="@/assets/images/arrow-to-left.svg" alt="Grower Plant" />
            </div>
            <div class="left-text-block-mobile">
              <img src="@/assets/images/arrow-to-right.svg" alt="Grower Plant" />
              <span>
                {{ $t('left_side_text') }}<br />
                <span class="underline">MIN</span>ING!<br />
                <span class="underline">TRAD</span>ING!<br />
              </span>
            </div>
            <div class="left-text-block-mobile">
              <img src="@/assets/images/arrow-to-right.svg" alt="Grower Plant" />
              <span>
                {{ $t('right_side_text') }}<br />
                <span class="underline">
                  GROWING!
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: 'HowItWorksSection'
}
</script>
<style scoped>
.underline {
  text-decoration: underline;
}

.section-how-it-works {
  margin-top: -3rem;
}

.how-it-works-inner {
  color: #2D2D2D;
  text-align: center;
}

.how-it-works {
  display: flex;
  flex-direction: row;
  width: 65%;
  margin: auto;
}

.how-it-works-inner span {
  width: 290px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.05em;
  opacity: 0.7;
  margin: auto;
}

.how-it-works-inner .left-text-block-desktop > span {
  text-align: left;
}

.how-it-works-inner .right-text-block-desktop > span {
  text-align: right;
}

.how-it-works > .left-text-block-desktop, .how-it-works > .right-text-block-desktop {
  display: flex;
  margin: auto;
  width: 30%;
}

.how-it-works > .left-text-block-mobile {
  display: none;
}

.how-it-works .plant {
  padding-bottom: 160px;
}

@media screen and (max-width: 1650px) {
  .how-it-works-inner span {
    width: 80%;
  }
}

@media screen and (max-width: 1024px) {
  .how-it-works {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .how-it-works {
    flex-direction: column;
    width: 100%;
  }

  .how-it-works .plant {
    padding-bottom: 60px;
  }

  .how-it-works > .left-text-block-desktop, .how-it-works > .right-text-block-desktop {
    display: none;
  }

  .how-it-works > .left-text-block-mobile {
    display: flex;
    margin: auto;
    width: 40%;
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 428px) {
  .how-it-works > .left-text-block-mobile {
    width: 60%;
  }
}

@media screen and (max-width: 320px) {
  .how-it-works > .left-text-block-mobile {
    width: 90%;
  }
}
</style>
