<template>
  <div>
    <span>
      <slot>
        Text
      </slot>
    </span>
  </div>
</template>
<script>
export default {
  name: 'ArrowLabel'
}
</script>
<style scoped>
div {
  background-image: url('../../assets/images/arrow-to-right-silver.svg');
  height: 86px;
  width: 170px;

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 210%;

  letter-spacing: 0.05em;
  color: #649187;
  opacity: 0.7;
}

span {
  position: relative;
  top: 20%;
}

@media screen and (max-width: 768px) {
  div {
    background-image: url('../../assets/images/arrow-down.svg');
    background-size: cover;
    height: 140px;
    margin: 30px 0;
  }

  span {
    position: unset;
    top: unset;
  }
}
</style>
