<template>
  <button type="button"
          :class="{ disabled: disabled, 'button-disabled': disabled, light: light }" class="btn btnPrimary"
          :disabled="disabled"
  >
    <slot>
      Submit
    </slot>
  </button>
</template>
<script>
export default {
  name: 'GenericButton',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    light: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.disabled {
  background-color: gray;
}

.light {
  background-color: #0BCA7C!important;
  border: 2px solid #FFFFFF!important;
}
</style>
