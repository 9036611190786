<template>
  <transition name="modal">
    <div class="modal__wrapper" @click="$emit('close')">
      <div class="modal-content" @click.stop="">
        <!-- header -->
        <div class="modal-header">
          <span class="modal-title"> {{ title }} </span>
          <span class="button-close" @click="$emit('close')">×</span>
        </div>
        <!-- body -->
        <div class="modal-body">
          <slot class="body-content" name="body" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  mounted () {
    document.body.addEventListener('keyup', e => {
      if (e.key === 'Escape') this.$emit('close')
    })
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
.body-content {
  text-align: left!important;
}

.body-content p, .body-content div {
  padding-bottom: 15px;
}

.body-content section p, .body-content section div {
  padding-bottom: 0;
}

.body-content li {
  list-style-image: url(../../assets/icons/bullet.svg);
}

//animations
.modal-enter, .modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-content,
.modal-leave-active .modal-content {
  transform: scale(1.2);
}

.modal__wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transition: opacity .2s ease;
  right: 0;
  z-index: 20;
  background-color: rgba(00,00,00,.48);
}

.modal-content {
  position: relative;
  max-width: 600px;
  max-height: 80rem;
  padding: 10px 50px;
  background-color: #fff;
  border: 1px solid #BDE5DD;
  transition: all .2s ease;
  border-radius: 5px;
  z-index: 999;
  @media screen and (min-width: 900px) {
    min-width: 570px;
  }

  @media screen and (max-width: 428px) {
    padding: 5px 0;
    width: 100%;
    height: 100%;
    max-height: unset;
  }
}

.modal-header {
  display: flex;
  align-self: center;
  justify-content: space-between;
  padding: 20px 0;
  color: #649187;
  span {
    font-size: 24px;
  }
  .modal-title {
    margin: auto;
  }
  .button-close {
    cursor: pointer;
    font-size: 40px;
  }

  @media screen and (max-width: 428px) {
    width: 95%;

    span {
      font-size: 20px;
    }
  }
}

.modal-body .body-content {
  text-align: center;
  overflow-y: scroll;
  height: 70vh;

  @media screen and (max-width: 428px) {
    max-height: 50rem;
  }
}

.modal-body {
  @media screen and (max-width: 428px) {
    padding: 0 15px;
  }
}

.modal-body .body-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.modal-body .body-content::-webkit-scrollbar {
  display: none;
}

.modal-bottom {
  padding-top: 40px;
}
</style>
