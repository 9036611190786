<template>
  <div class="badge">
    <span class="pulse-button" @click="$bus.$emit('contact-us-click')">
      <img src="@/assets/icons/phone-white.svg" :alt="$t('contact_us')" />
    </span>
  </div>
</template>
<script>
export default {
  name: 'CallBackButton'
}
</script>
<style lang="scss" scoped>
.badge {
  position: fixed;
  right: 5%;
  bottom: 10%;
  text-align: center;
  z-index: 997;

  .pulse-button {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: white;
    border: none;
    border-radius: 50%;
    background: #009357;;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(15, 210, 131, 0.5);
    -webkit-animation: pulse 1.5s infinite;
  }

  .pulse-button:hover {
    -webkit-animation: none;
  }

  img {
    display: block;
    margin: 25px auto;
    width: 32px;
    height: 32px;
  }
}

@-webkit-keyframes pulse {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }
  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}
</style>
