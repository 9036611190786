<template>
  <div v-if="validation">
    <template>
          <span v-if="validation.condition" class="block cl-error h6 mt8" data-testid="errorMessage">
            {{ validation.text }}
          </span>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    validations: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    invalids () {
      return this.validations.filter(v => v.condition)
    },
    validation () {
      return this.invalids.length ? this.invalids[0] : false
    }
  }
}
</script>
