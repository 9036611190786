<template>
  <section class="section-roadmap">
    <div class="roadmap-outer">
      <div class="roadmap-inner">
        <h2>
          {{ $t('roadmap') }}
        </h2>
        <div class="roadmap-content">
          <roadmap-item v-for="(point, i) in points" :item="point" :key="point.id"
                        :date="new Date(point.date)"
                        :title="point.title"
                        :description="point.description"
                        :thumbnail="point.thumbnail"
                        :category="point.category"
                        :icon="point.done ? 'check' : 'spa'"
                        color="white"
                        :is-last="(i + 1) === points.length"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import RoadmapItem from '@/components/Landing/RoadmapSection/RoadmapItem'
import request from '@/server/request'

export default {
  name: 'RoadmapSection',
  components: {
    RoadmapItem
  },
  mounted () {
    this.$bus.$on('language-changed', this.loadRoadmapItems)
    if (!localStorage.getItem('locale')) {
      let t = setInterval(() => {
        if (localStorage.getItem('locale')) {
          this.loadRoadmapItems({ code: this.langsMap[localStorage.getItem('locale')] })
          clearInterval(t)
        }
      }, 50)
    } else {
      this.loadRoadmapItems({ code: this.langsMap[localStorage.getItem('locale')] })
    }
  },
  data: () => ({
    points: [],
    pointsLoading: false,
    langsMap: {
      'en-US': 'en',
      'uk-UA': 'uk'
    }
  }),
  methods: {
    loadRoadmapItems (newLanguage) {
      this.pointsLoading = true
      return request(
        { entity: 'landing.roadmap', action: 'load' },
        null,
        'GET',
        { language: newLanguage.code }
      )
        .then(result => {
          this.points = result.data
          return result.data
        })
        .finally(() => {
          this.pointsLoading = false
        })
    }
  },
  beforeDestroy () {
    this.$bus.$off('language-changed', this.loadRoadmapItems)
  }
}
</script>
<style>
.gb-vue-timeline-update {
  max-width: 50rem;
  font-family: "Raleway" !important;
  margin: auto;
}

.gb-vue-timeline-update__right {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  margin-bottom: 25px;
  padding: 10px 25px 0;
}

.gb-vue-timeline-update:hover .gb-vue-timeline-update__right {
  border-color: #009357;
}

.gb-base-number--dark {
  background-color: #fff;
}

.gb-base-badge {
  box-shadow: none!important;
  border-radius: 2px!important;;
  border: 1px solid #009357!important;
  font-style: normal;
  letter-spacing: 0.05em;
  color: #009357!important;;
}

.gb-base-number__icon.gb-base-icon {
  font-size: 24px !important;
  color: #009357!important;
}

.gb-vue-timeline-update__ago {
  color: #009357 !important;
  font-weight: 800;
}

.gb-vue-timeline-update__title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.05em;
  color: #649187;
}

.gb-vue-timeline-update__thumbnail {
  width: 450px !important;
  max-height: 408px;
}

.gb-vue-timeline-update__description {
  color: #649187 !important;
  letter-spacing: 0.05em;
}
</style>
<style scoped>
.roadmap-inner img {
  text-align: center;
}

h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #000000;
  text-align: center;
}

.roadmap-content {
  padding-top: 65px;
}

.roadmap-content .tooltiped-label:first-of-type {
  top: 135px;
  left: 64%
}

.roadmap-content .tooltiped-label:nth-of-type(2) {
  top: 486px;
  left: 49%;
}

.roadmap-content .tooltiped-label:last-of-type {
  top: 315px;
  left: 19%;
}
</style>
