<template>
  <landing />
</template>

<script>
import Landing from '@/components/Landing'
import request from '@/server/request'

export default {
  name: 'App',
  components: {
    Landing
  },
  created () {
    window.___SETTINGS___ = []
    this.$bus.$on('language-changed', this.loadSettings)
  },
  methods: {
    loadSettings (newLanguage) {
      return request(
        { entity: 'settings', action: 'load' },
        null,
        'GET',
        { language: newLanguage.code }
      )
        .then(result => {
          window.___SETTINGS___ = result.data
          return result.data
        })
    }
  },
  beforeDestroy () {
    this.$bus.$off('language-changed', this.loadSettings)
  }
}
</script>

<style>
:root{
  --font-color: hsl(233, 26%, 24%);
  --second-font-color: hsl(151, 30%, 32%);
  --third-font-color: hsl(233, 8%, 62%);
  --fourth-color: hsl(136, 65%, 51%);
  --fifth-color: hsl(192, 70%, 51%);
}
*, ::before, ::after{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 5px;
  heigth: 5px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  border: 5px solid #BDE5DD;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  border: 5px solid #009357;
}
body{
  font-size: 1.2em;
  color: var(--font-color);
  background-color: #DAF0E9;
}

body.with-modal {
  overflow: hidden;
}

</style>
