var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"title":_vm.$t('contact_us')},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"body",fn:function(){return [(!_vm.received)?[_c('generic-input',{attrs:{"type":"text","name":"firstname","placeholder":_vm.$t('firstname_field'),"validations":[
                     {
                       condition: _vm.$v.name.$error && !_vm.$v.name.required,
                       text: _vm.$t('required')
                     },
                     {
                       condition: _vm.$v.name.$error && !_vm.$v.name.minLength,
                       text: _vm.$t('min_characters', { number: 4 })
                     }
                   ]},on:{"blur":function($event){return _vm.$v.name.$touch()}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('generic-input',{attrs:{"type":"number","name":"phone_number","placeholder":_vm.$t('phone_number_field'),"validations":[
                     {
                       condition: _vm.$v.phone_number.$error && !_vm.$v.phone_number.required,
                       text: _vm.$t('required')
                     },
                     {
                       condition: _vm.$v.phone_number.$error && !_vm.$v.phone_number.minLength,
                       text: _vm.$t('min_characters', { number: 10 })
                     }
                   ]},on:{"blur":function($event){return _vm.$v.phone_number.$touch()}},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}}),_c('generic-input',{attrs:{"type":"email","name":"email","placeholder":_vm.$t('email_field'),"validations":[
                     {
                       condition: _vm.$v.email.$error && !_vm.$v.email.required,
                       text: _vm.$t('required')
                     },
                     {
                       condition: _vm.$v.email.$error && !_vm.$v.email.email,
                       text: _vm.$t('email_must_be_valid')
                     }
                   ]},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticClass:"actions"},[_c('generic-button',{attrs:{"disabled":_vm.$v.invalid},nativeOn:{"click":function($event){return _vm.submit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")])],1)]:_c('div',[_vm._v(" "+_vm._s(_vm.$t('we_received_your_request'))+" ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }