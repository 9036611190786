<template>
  <article class="gb-vue-timeline-update gb-vue-timeline-update--dark js-vue-timeline-update"
           :class="{ 'gb-vue-timeline-update--is-last': isLast }"
  >
    <div class="gb-vue-timeline-update__left">
      <span class="gb-vue-timeline-update__ago">
        {{ ago }}
      </span>
    </div>
    <div class="gb-vue-timeline-update__center">
      <base-number class="gb-vue-timeline-update__bullet"
                   :color="color" :icon="icon" theme="dark"
                   icon-size="16px" size="small"
      />
      <span class="gb-vue-timeline-update__line"></span>
    </div>
    <div class="gb-vue-timeline-update__right">
      <div class="gb-vue-timeline-update__information">
        <div class="gb-vue-timeline-update__meta">
          <base-badge v-if="item.category" class="gb-vue-timeline-update__category"
                      :color="color"
                      :filled="true"
                      theme="dark"
                      size="small"
          >
            {{ $t(item.category) }}
          </base-badge>
          <span class="gb-vue-timeline-update__ago">{{ ago }}</span>
        </div>
        <span v-html="item.title" class="gb-vue-timeline-update__title" />
      </div>
      <div v-if="item.images && item.images.length" class="images">
        <template v-for="image in item.images">
          <img v-lazy="image.path" class="gb-vue-timeline-update__thumbnail" :key="image.id" />
        </template>
      </div>
      <p class="gb-vue-timeline-update__description" v-if="item.description" v-html="item.description"></p>
      <div class="gb-vue-timeline-update__slot" v-if="$slots.default">
        <slot name="default"></slot>
      </div>
    </div>
  </article>
</template>
<script>

import { BaseBadge, BaseNumber } from '@growthbunker/vuedarkmode'
import { format, register } from 'timeago.js'
import { uk } from 'timeago.js/lib/lang/index'
import ThemeMixin from './theme.js'

register('uk', uk)

export default {
  name: 'RoadmapItem',

  components: {
    BaseBadge,
    BaseNumber
  },
  mixins: [ThemeMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    category: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'blue',
      validator (x) {
        return ['black', 'blue', 'green', 'orange', 'purple', 'red', 'turquoise', 'white'].includes(x)
      }
    },
    date: {
      type: Date,
      required: true
    },
    dateString: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      required: true
    },
    isLast: {
      type: Boolean,
      default: false
    },
    thumbnail: {
      type: String,
      default: null
    },
    title: {
      type: String,
      required: true
    }
  },
  data: () => ({
    defaultLang: 'uk',
    langsMap: {
      'en-US': 'en',
      'uk-UA': 'uk'
    }
  }),
  computed: {
    ago () {
      let urlParams = new URLSearchParams(window.location.search)
      let lang = localStorage.getItem('locale') ? this.langsMap[localStorage.getItem('locale')] : this.defaultLocale
      if (urlParams.get('lang')) {
        lang = urlParams.get('lang')
      }
      return this.dateString || format(this.date, lang)
    }
  }
}
</script>
<style lang="scss">
// IMPORTS
@import "@growthbunker/stylesheets/settings/_colors.scss";
@import "@growthbunker/stylesheets/settings/_themes.scss";
@import "@growthbunker/stylesheets/tools/_functions.scss";
@import "@growthbunker/stylesheets/tools/_mq.scss";
// VARIABLES
$c: ".gb-vue-timeline-update";
#{$c} {
  display: flex;
  overflow: hidden;
  text-align: left;
  a {
    text-decoration: underline;
  }
  #{$c}__left,
  #{$c}__right  {
    #{$c}__ago {
      font-size: 14px;
      user-select: none;
    }
  }
  #{$c}__left {
    display: none;
  }
  #{$c}__center {
    position: relative;
    flex: 0 0 auto;
    margin-right: 30px;
    margin-left: 16px;
    #{$c}__bullet {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    #{$c}__line {
      display: inline-block;
      margin-top: 32px;
      width: 1px;
      height: 100%;
    }
  }
  #{$c}__right {
    flex: 1;
    padding-bottom: 40px;
    #{$c}__title,
    #{$c}__description {
      font-size: 16px;
      line-height: 26px;
    }
    #{$c}__information {
      display: flex;
      flex-direction: column;
      margin-top: 4px;
      #{$c}__meta {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        #{$c}__category {
          align-self: flex-start;
          flex: 0 0 auto;
          margin-right: 10px;
        }
        #{$c}__ago {
          line-height: 24px; // Size of the category to align horizontally
        }
      }
      #{$c}__title {
        flex: 1;
        margin: 0 0 4px;
        text-transform: uppercase;
        font-weight: bold;
        &--clickable {
          cursor: pointer;
        }
      }
    }
    #{$c}__description {
      margin: 0;
    }
    #{$c}__thumbnail {
      box-sizing: border-box;
      margin: 6px 0 12px;
      max-width: 100%;
      border-width: 1px;
      border-style: solid;
      border-radius: 6px;
      transition: all 250ms linear;
      user-select: none;
      &--clickable {
        cursor: pointer;
      }
    }
    #{$c}__slot {
      margin-top: 20px;
    }
    &--clickable {
      cursor: pointer;
    }
  }
  // --> BOOLEANS <--
  &--is-last {
    #{$c}__right {
      padding-bottom: 20px;
    }
  }
  // --> THEMES <--
  @each $theme in $themes {
    $themeName: map-get($theme, "name");
    &--#{$themeName} {
      color: mdg($theme, "fonts", "default", "primary");
      a {
        color: mdg($theme, "fonts", "default", "primary");
      }
      #{$c}__left,
      #{$c}__right  {
        #{$c}__ago {
          color: mdg($theme, "fonts", "default", "secondary");
        }
      }
      #{$c}__center {
        #{$c}__bullet {
          box-shadow: 0 1px 5px 0 mdg($theme, "shadows", "default", "primary");
        }
        #{$c}__line {
          background-color: mdg($theme, "borders", "default", "primary");
        }
      }
      #{$c}__right {
        #{$c}__information {
          #{$c}__meta {
            #{$c}__category {
              box-shadow: 0 1px 5px 0 mdg($theme, "shadows", "default", "primary");
            }
          }
        }
        #{$c}__thumbnail {
          border-color: mdg($theme, "borders", "reverse", "primary");
        }
        #{$c}__description {
          color: mdg($theme, "fonts", "default", "secondary");
        }
      }
      // --> BOOLEANS <--
      &#{$c}--is-last {
        #{$c}__center {
          #{$c}__line {
            background: linear-gradient(
                    mdg($theme, "borders", "default", "primary") 50%,
                    rgba(mdg($theme, "borders", "default", "primary"), 0)
            );
          }
        }
      }
    }
  }
}

.images {
  display: flex;
  max-width: 50%;
}

.images:only-child {
  max-width: unset;
}

.images > img {
  flex: 1 1;
}
// --> BREAKPOINT: TABLET <--
@include mq($from: tablet) {
  #{$c} {
    #{$c}__left {
      display: block;
      flex: 0 0 auto;
      width: 120px;
      text-align: right;
      #{$c}__ago {
        display: inline-block;
        line-height: 32px; // Size of the badge to align horizontally
      }
    }
    #{$c}__center {
      margin-right: 40px;
      margin-left: 40px;
    }
    #{$c}__right {
      #{$c}__title,
      #{$c}__description {
        font-size: 18px;
        line-height: 28px;
      }
      #{$c}__information {
        flex-direction: row;
        #{$c}__meta {
          #{$c}__ago {
            display: none;
          }
        }
        #{$c}__title {
          margin-bottom: 8px;
        }
      }
      #{$c}__thumbnail {
        margin: 8px 0 16px;
      }
    }
  }
}
</style>
