<template>
  <modal :title="$t('contact_us')" @close="$emit('close')">
    <template #body>
      <template v-if="!received">
        <generic-input v-model="name"
                       @blur="$v.name.$touch()"
                       type="text"
                       name="firstname"
                       :placeholder="$t('firstname_field')"
                       :validations="[
                       {
                         condition: $v.name.$error && !$v.name.required,
                         text: $t('required')
                       },
                       {
                         condition: $v.name.$error && !$v.name.minLength,
                         text: $t('min_characters', { number: 4 })
                       }
                     ]"
        />
        <generic-input v-model="phone_number"
                       @blur="$v.phone_number.$touch()"
                       type="number"
                       name="phone_number"
                       :placeholder="$t('phone_number_field')"
                       :validations="[
                       {
                         condition: $v.phone_number.$error && !$v.phone_number.required,
                         text: $t('required')
                       },
                       {
                         condition: $v.phone_number.$error && !$v.phone_number.minLength,
                         text: $t('min_characters', { number: 10 })
                       }
                     ]"
        />
        <generic-input v-model="email"
                       @blur="$v.email.$touch()"
                       type="email"
                       name="email"
                       :placeholder="$t('email_field')"
                       :validations="[
                       {
                         condition: $v.email.$error && !$v.email.required,
                         text: $t('required')
                       },
                       {
                         condition: $v.email.$error && !$v.email.email,
                         text: $t('email_must_be_valid')
                       }
                     ]"
        />

        <div class="actions">
          <generic-button @click.native="submit" :disabled="$v.invalid">
            {{ $t('confirm') }}
          </generic-button>
        </div>
      </template>
      <div v-else>
        {{ $t('we_received_your_request') }}
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Core/Modal'
import GenericButton from '@/components/Core/Form/GenericButton'
import GenericInput from '@/components/Core/Form/GenericInput'
import { email, minLength, required } from 'vuelidate/lib/validators'
import request from '@/server/request'

export default {
  components: {
    Modal,
    GenericButton,
    GenericInput
  },
  data: () => ({
    name: '',
    phone_number: '',
    email: '',
    received: false
  }),
  methods: {
    submit () {
      return request({ entity: 'contact_us', action: 'act' }, null, 'POST', {
        name: this.name,
        phone_number: this.phone_number,
        email: this.email
      }).then(() => (this.received = true))
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    phone_number: {
      required,
      minLength: minLength(10)
    },
    email: {
      required,
      email
    }
  }
}
</script>

<style lang="scss">
.actions {
  display: flex;
  padding-bottom: 15px;
  button {
    min-width: 200px;
  }

  @media screen and (max-width: 428px) {
    button {
      min-width: 150px;
    }
  }
}
</style>
