<template>
  <button @click="$bus.$emit('join-us-click')">
    {{ $t('join_us') }}
  </button>
</template>

<script>
export default {
  name: 'JoinUsButton'
}
</script>

<style scoped>
button {
  width: 270px;
  height: 80px;
  background: #009357;
  border-radius: 5px;
  border: none;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 18px */

  letter-spacing: 0.05em;

  color: #FFFFFF;

  cursor: pointer;
}

header button {
  width: 200px;
  height: 60px;
  background: white;
  color: #009357;
  border: 2px solid #009357;
}

header button:hover {
  background: #009357;
  color: #FFFFFF;
}
@media screen and (max-width: 768px) {
  header button {
    display: none;
  }
}
</style>
