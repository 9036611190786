<template>
  <div>
    <main-header ref="header" @scroll-to="scrollToSection" />
    <main :class="{ headerIsFixed }">
      <main-section ref="main" @scroll-to="scrollToSection" />
      <our-mission-section ref="our_mission" />
      <how-it-works-section ref="how_it_works" />
      <roadmap-section ref="roadmap" />
      <join-us-section ref="join_us" />
      <contacts-section ref="contacts" />
    </main>
    <main-footer @scroll-to="scrollToSection" />
    <join-us-modal v-if="modal" @close="modal = false; removeBodyModalClass()" />
    <contact-us-modal v-if="contactUsModal" @close="contactUsModal = false; removeBodyModalClass()" />
    <details-modal v-if="detailsModal" @close="detailsModal = false; removeBodyModalClass()" />
    <trade-details-modal v-if="tradeDetailsModal" @close="tradeDetailsModal = false; removeBodyModalClass()" />
    <calculator-modal v-if="calculatorModal" @close="calculatorModal = false; removeBodyModalClass()" />
    <call-back-button />
  </div>
</template>

<script>
import Header from '@/components/Landing/Header'
import MainSection from '@/components/Landing/MainSection'
import HowItWorksSection from '@/components/Landing/HowItWorksSection'
import OurMissionSection from '@/components/Landing/OurMissionSection'
import RoadmapSection from '@/components/Landing/RoadmapSection'
import JoinUsSection from '@/components/Landing/JoinUsSection'
import ContactsSection from '@/components/Landing/ContactsSection'
import Footer from '@/components/Landing/Footer'
import JoinUsModal from '@/components/JoinUsModal'
import ContactUsModal from '@/components/ContactUsModal'
import DetailsModal from '@/components/DetailsModal'
import TradeDetailsModal from '@/components/TradeDetailsModal'
import CalculatorModal from '@/components/CalculatorModal'
import CallBackButton from '@/components/CallBackButton'

export default {
  name: 'Landing',
  components: {
    'main-header': Header,
    MainSection,
    HowItWorksSection,
    OurMissionSection,
    RoadmapSection,
    JoinUsSection,
    ContactsSection,
    'main-footer': Footer,
    JoinUsModal,
    ContactUsModal,
    DetailsModal,
    TradeDetailsModal,
    CalculatorModal,
    CallBackButton
  },
  computed: {
    headerIsFixed () {
      if (!this.$refs.header) {
        return false
      }
      return this.$refs.header.fixedStatus.headerIsFixed
    }
  },
  data: () => ({
    modal: false,
    contactUsModal: false,
    detailsModal: false,
    tradeDetailsModal: false,
    calculatorModal: false
  }),
  metaInfo () {
    return {
      title: this.$t('meta_title'),
      htmlAttrs: {
        lang: 'ua'
      },
      meta: [
        {
          name: 'description',
          content: this.$t('meta_description')
        },
        {
          name: 'og:title',
          content: this.$t('meta_title')
        },
        {
          name: 'og:description',
          content: this.$t('meta_description')
        },
        {
          name: 'og:type',
          content: 'article'
        },
        {
          name: 'og:image',
          content: require('@/assets/images/logo.svg')
        }
      ]
    }
  },
  mounted () {
    this.$bus.$on('join-us-click', this.onJoinUsClick)
    this.$bus.$on('contact-us-click', this.onContactUsClick)
    this.$bus.$on('details-click', this.onDetailsClick)
    this.$bus.$on('trade-details-click', this.onTradeDetailsClick)
    if (window.location.href.includes('calculator')) {
      this.calculatorModal = true
    }
  },
  methods: {
    onJoinUsClick () {
      this.$gtag.event('join_us_button_click')
      this.calculatorModal = false
      this.modal = !this.modal
      if (this.modal) {
        this.addBodyModalClass()
      } else {
        this.removeBodyModalClass()
      }
    },
    onContactUsClick () {
      this.$gtag.event('contact_us_button_click')
      this.contactUsModal = !this.contactUsModal
      if (this.modal) {
        this.addBodyModalClass()
      } else {
        this.removeBodyModalClass()
      }
    },
    onDetailsClick () {
      this.$gtag.event('details_button_click')
      this.detailsModal = !this.detailsModal
      if (this.modal) {
        this.addBodyModalClass()
      } else {
        this.removeBodyModalClass()
      }
    },
    onTradeDetailsClick () {
      this.$gtag.event('trade_details_button_click')
      this.tradeDetailsModal = !this.tradeDetailsModal
      if (this.modal) {
        this.addBodyModalClass()
      } else {
        this.removeBodyModalClass()
      }
    },
    scrollToSection (sectionName) {
      let el = this.$refs[sectionName].$el
      if (el) {
        el.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' })
        this.$gtag.event(`scroll_to_${sectionName}`)
      }
    },
    addBodyModalClass () {
      document.body.classList.add('with-modal')
    },
    removeBodyModalClass () {
      document.body.classList.remove('with-modal')
    }
  },
  beforeUnmount () {
    this.$bus.$off('join-us-click', this.onJoinUsClick)
    this.$bus.$off('contact-us-click', this.onContactUsClick)
    this.$bus.$off('details-click', this.onDetailsClick)
    this.$bus.$off('trade-details-click', this.onTradeDetailsClick)
  }
}
</script>

<style scoped>
main {
  padding-top: 6rem;
}

@media screen and (max-width: 768px) {
  main {
    padding-top: 0;
  }
}
</style>
