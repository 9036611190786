import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import VueGtag from 'vue-gtag'
import VueMeta from 'vue-meta'
import VueLazyload from 'vue-lazyload'
import i18n from '@/plugins/i18n'
import mdiVue from 'mdi-vue/v2'
import * as mdijs from '@mdi/js'
import './assets/scss/main.scss'
import * as configMain from '@/config/local.json'
let config = configMain.default

const loadImage = require('./assets/images/logo.png')
const errorImage = require('./assets/images/logo.png')

Vue.config.productionTip = false

// Plugins
Vue.use(Vuelidate)

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: loadImage,
  loading: errorImage,
  attempt: 1
})

Vue.use(VueMeta)

Vue.use(VueGtag, {
  config: {
    id: config.analytics.id,
    params: {
      send_page_view: true
    }
  }
})

Vue.use(mdiVue, {
  icons: mdijs
})

/**
 * We will use another Vue instance as a global
 * event handler (subscribe / emit events)
 */
Vue.prototype.$bus = new Vue()

// Initialize Vue.js application
// eslint-disable-next-line
export default new Vue({
  el: '#app',
  i18n,
  render: h => h(App)
})
