<template>
  <section class="section-donations">
    <div class="join-us-outer">
      <div class="join-us-inner">
        <h2>
          {{ $t('join_us') }}
        </h2>
        <p>
          {{ $t('join_us_text') }}
        </p>
        <div class="join-us-phone">
          <div class="arrow">
            <img src="@/assets/images/arrow-to-right-light.svg" alt="Grower Join Us" />
          </div>
          <div class="phone">
            <span class="pulse-button" @click="$bus.$emit('join-us-click')">
              {{ $t('join_us') }}
            </span>
          </div>
          <div class="arrow">
            <img src="@/assets/images/arrow-to-left-light.svg" alt="Grower Join Us" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: 'JoinUsSection'
}
</script>
<style scoped>
.join-us-outer {
  border-top: 67px solid transparent;
  border-image: url(../../assets/images/green-grass.svg) 50 round;
}

.join-us-inner {
  background-color: #009357;
  color: white;
}

.join-us-inner .join-us-phone {
  position: relative;
  padding-top: 65px;
  display: flex;
  align-items: center;
  max-width: 80%;
  margin: auto;
}

.phone {
  background-image: url(../../assets/images/join-us-phone.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 18rem;
  height: 32rem;
  display: flex;
  margin: auto;
  flex: 1 1 360px;
}

h2 {
  padding-top: 90px;
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 72px;
  line-height: 100%;
  letter-spacing: 0.05em;
}

p {
  padding-top: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.05em;
  opacity: 0.7;
  max-width: 790px;
  margin: auto;
}

.pulse-button {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11rem;
  height: 11rem;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: white;
  border: none;
  border-radius: 50%;
  background: #009357;;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(15, 210, 131, 0.5);
  -webkit-animation: pulse 1.5s infinite;
}

.pulse-button:hover {
  -webkit-animation: none;
}

@media screen and (max-width: 2560px) {
  .phone {
    width: 22rem;
    flex: unset;
  }
}

@media screen and (max-width: 768px) {
  .join-us-inner .join-us-phone {
    max-width: 100%;
  }

  .arrow {
    display: none;
  }

  .phone {
    flex: unset;
  }

  p {
    text-align: center;
  }
}

@media screen and (max-width: 428px) {
  h2 {
    font-size: 32px;
  }
}

@-webkit-keyframes pulse {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }
  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}
</style>
