<template>
  <modal :title="$t('calculator')" @close="$emit('close')">
    <template #body>
      <div>
        <h6>
          {{ $t('select_product') }}
        </h6>
        <generic-dropdown :selected="currentProductKey"
                          :options="plants"
                          :label="$t('product')"
                          @change="changePlant"
        />
        <h6>
          {{ $t('enter_amount_of_money') }}
        </h6>
        <generic-input v-model="amount"
                       type="number"
                       name="amount"
        />
        <h6>
          {{ $t('select_investment_period') }}
        </h6>
        <div class="period">
          <div @click="changePeriod(period)" v-for="period in periods" :key="period.period" class="period-option" :class="{ active: period.period === selectedPeriod.period }">
            {{ period.label }}
          </div>
        </div>
        <div class="summary">
          <template v-if="selectedPeriod && newAmount && potentialEarnings">
            <div>{{ $t('new_purchase_value_text', { period: selectedPeriod.label, new_value: newAmount}) }}</div>
            <div>{{ $t('total_earnings_text', { new_value: potentialEarnings }) }}</div>
          </template>
          <template v-else>
            <div>{{ $t('loading') }}</div>
          </template>
        </div>
        <div class="actions">
          <join-us-button />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Core/Modal'
import GenericButton from '@/components/Core/Form/GenericButton'
import GenericInput from '@/components/Core/Form/GenericInput'
import GenericDropdown from '@/components/Core/Form/GenericDropdown'
import JoinUsButton from '@/components/JoinUsButton'
import request from '@/server/request'

export default {
  components: {
    Modal,
    GenericButton,
    GenericInput,
    GenericDropdown,
    JoinUsButton
  },
  data: () => ({
    selectedPeriod: null,
    products: [],
    product: null,
    amount: 1000,
    newAmount: 0,
    potentialEarnings: 0
  }),
  async mounted () {
    this.$gtag.event('calculator_opened')
    await this.loadProduct()
    this.selectedPeriod = this.periods[0]
    await this.calculate()
    this.pushEvent('calculator_loaded')
  },
  computed: {
    currentProductKey () {
      return this.product ? this.product.toString() : null
    },
    currentProduct () {
      return this.product ? this.products.find(p => p.id === parseInt(this.product)) : null
    },
    plants () {
      return this.products.map(p => {
        return {
          label: `${p.name} $${p.base_price_per_unit}`,
          value: p.id
        }
      })
    },
    periods () {
      return [
        { label: this.$t('6month'), period: 183 },
        { label: this.$t('1year'), period: 365 },
        { label: this.$t('2years'), period: 730 },
        { label: this.$t('3years'), period: 1095 }
      ]
    }
  },
  methods: {
    async loadProduct () {
      if (!this.products.length) {
        await request({ entity: 'auction.buy_request', action: 'load_product' })
          .then(result => {
            this.products = result.data
            this.product = this.products[0].product_id
          })
      }
    },
    async calculate () {
      await request({ entity: 'landing.calculator', action: 'calculate' }, null, 'GET', {
        id: this.product,
        amount: this.amount,
        period: this.selectedPeriod.period
      })
        .then(result => {
          this.newAmount = result.payload.new_total_price
          this.potentialEarnings = result.payload.possible_earnings
        })
    },
    changePlant (product) {
      this.product = product
      this.calculate()
      this.pushEvent('calculator_plant_changed')
    },
    changePeriod (period) {
      this.selectedPeriod = period
      this.calculate()
      this.pushEvent('calculator_period_changed')
    },
    pushEvent (eventName) {
      this.$gtag.event(eventName, {
        affiliation: 'Grower Landing',
        value: this.amount,
        currency: 'USD',
        items: [
          {
            item_id: this.currentProduct.id,
            item_name: this.currentProduct.name,
            quantity: parseInt(this.amount / this.currentProduct.base_price_per_unit),
            price: this.currentProduct.base_price_per_unit
          }
        ]
      })
    }
  },
  watch: {
    amount: function () {
      this.calculate()
      this.pushEvent('calculator_amount_changed')
    }
  }
}
</script>

<style lang="scss" scoped>
h6, .summary {
  font-size: 18px;
  line-height: 100%;
  color: #649187;
  margin-bottom: 20px;
}

.summary {
  margin-bottom: 40px;
}

.period {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.period-option {
  border: 1px solid #BDE5DD;
  border-radius: 5px;
  text-align: center;
  color: #009357;
  cursor: pointer;
  padding: 10px 15px;
  flex: 1;
  margin: 0 10px;
  font-size: 16px;

  &:hover {
    border: 1px solid #009357;
    font-weight: 700;
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.period-option.active {
  border: 1px solid #009357;
  background-color: #009357;
  font-weight: 700;
  color: white;
}

.actions {
  display: flex;
  padding-bottom: 15px;
  text-align: center;
  button {
    margin: auto;
    min-width: 200px;
  }

  @media screen and (max-width: 428px) {
    button {
      min-width: 150px;
    }
  }
}
</style>
