<template>
  <header>
    <div class="header-wrapper">
      <img @click="toggleMobileMenu" src="@/assets/icons/burger-menu.svg" alt="Grower's Menu" class="burger-menu" />
      <img @click="$emit('scroll-to', 'main')" src="@/assets/images/logo.svg" alt="Grower's logo" class="logo-header" />
      <img @click="$bus.$emit('join-us-click')" src="@/assets/icons/profile.svg" alt="Join Grower's" class="join-us-icon" />
      <nav class="nav-header" :class="mobile.menu ? 'open-menu' : 'closed-menu'">
        <ul class="ul-header light-green-text-color">
          <li @click="$emit('scroll-to', 'our_mission')">
            {{ $t('our_mission') }}
          </li>
          <li @click="$emit('scroll-to', 'roadmap')">
            {{ $t('roadmap') }}
          </li>
          <li @click="$emit('scroll-to', 'join_us')">
            {{ $t('join_us') }}
          </li>
          <li @click="$emit('scroll-to', 'contacts')">
            {{ $t('contacts') }}
          </li>
        </ul>
      </nav>
      <join-us-button />
      <language-switcher />
    </div>
  </header>
</template>
<script>
import JoinUsButton from '@/components/JoinUsButton'
import LanguageSwitcher from '@/components/LanguageSwitcher'

export default {
  name: 'Header',
  components: {
    JoinUsButton,
    LanguageSwitcher
  },
  computed: {
    isMobile () {
      return window.innerWidth <= 768
    }
  },
  data: () => ({
    fixedStatus: {
      headerIsFixed: false
    },
    propsData: {
      threshold: 0,
      headerClass: 'vue-fixed-header',
      fixedClass: 'fixed-header--isFixed',
      hideScrollUp: false
    },
    mobile: {
      menu: false
    }
  }),
  methods: {
    updateFixedStatus (next) {
      this.fixedStatus.headerIsFixed = next
    },
    toggleMobileMenu () {
      this.mobile.menu = !this.mobile.menu
    }
  }
}
</script>
<style scoped>
header {
  position: fixed;
  background: white;
  width: 100%;
  transition: all 200ms;
  z-index: 10;
}

.header-wrapper {
  display: flex;
  width: 80%;
  margin: auto;
}

.burger-menu, .join-us-icon {
  display: none;
}

.header-wrapper .logo-header, .header-wrapper button {
  margin: auto;
}

.nav-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 100px;
  align-items: center;
  flex: 1 1;
}

.logo-header {
  height: 2.5rem;
  cursor: pointer;
}

.ul-header {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  color: #649187;
  width: 90%;
  text-align: center;
  margin: auto;
}

.ul-header > li {
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  list-style-type: none;
  list-style-image: none;
  padding: 1rem;
  cursor: pointer;
}

.ul-header > li:hover {
  background-color: #0BCA7C;
  color: #fff;
  border-radius: 40px;
}

@media screen and (max-width: 2560px) {
  .ul-header {
    width: 70%;
  }
}

@media screen and (max-width: 1920px) {
  .ul-header {
    width: 90%;
  }
}

@media screen and (max-width: 1440px) {
  .header-wrapper {
    width: 95%;
  }

  .nav-header {
    padding: 10px 80px;
  }

  .ul-header {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .nav-header {
    padding: 10px 40px;
  }

  .ul-header {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  header {
    padding-top: 10px;
  }

  .header-wrapper {
    flex-wrap: wrap;
  }

  .header-wrapper > * {
    flex: 0 1 50px;
  }

  .header-wrapper > .logo-header {
    flex: 1 1 50px;
  }

  .open-menu {
    opacity: 1;
    order: 3;
    width: 100%;
    flex: 1 1 428px;
  }

  .closed-menu {
    display: none;
    opacity: 0;
    height: 0;
    padding: 0;
  }

  .nav-header {
    flex-direction: column;
    z-index: 100;
    position: relative;
    transition: all 0.2s ease-out;
  }

  .ul-header {
    flex-direction: column;
  }

  .burger-menu, .join-us-icon {
    width: 36px;
    height: 36px;
    display: inline-block;
    text-align: right;
    margin: 15px;
  }
}
@media screen and (max-width: 428px) {
  .header-wrapper {
    width: 100%;
  }

  .header-wrapper > * {
    flex: 0 1 36px;
    margin: 15px 5px;
  }
}
</style>
