<template>
  <footer>
    <div class="footer-outer">
      <div class="footer-wrapper">
        <div class="footer-inner">
          <div class="logo-footer">
            <img @click="$emit('scroll-to', 'header')" src="@/assets/images/logo-footer.svg" alt="Grower's logo" />
            <div class="payments-logos">
              <img src="@/assets/images/mc_logo.svg" alt="Mastercard" />
              <img src="@/assets/images/visa_logo.svg" alt="VISA" />
            </div>
          </div>
          <div class="menu-footer">
            <ul class="ul-footer light-green-text-color">
              <li @click="$emit('scroll-to', 'our_mission')">
                {{ $t('our_mission') }}
              </li>
              <li @click="$emit('scroll-to', 'roadmap')">
                {{ $t('roadmap') }}
              </li>
              <li @click="$emit('scroll-to', 'join_us')">
                {{ $t('join_us') }}
              </li>
              <li @click="$emit('scroll-to', 'contacts')">
                {{ $t('contacts') }}
              </li>
            </ul>
          </div>
          <div class="menu-footer padding-top">
            <div>
              {{ $t('footer_documents_title') }}
            </div>
            <ul class="ul-footer light-green-text-color">
              <li>
                <a href="https://docs.google.com/document/d/1QvIO2XeSKVOOK8T4PxhKNlg4tQzt5s0-P84pOS6XLvE/edit#">
                  {{ $t('privacy_policy') }}
                </a>
              </li>
              <li>
                <a @click.stop="frontDownload('grower_agreement_purchase')">
                  {{ $t('grower_agreement_purchase') }}
                </a>
              </li>
              <li>
                <a @click.stop="frontDownload('grower_agreement_storage')">
                  {{ $t('grower_agreement_storage') }}
                </a>
              </li>
            </ul>
          </div>
          <div class="social-links-footer">
            <a href="https://www.facebook.com/grower_invest-107352865442627/" target="_blank">
              <img src="@/assets/icons/facebook-white.svg" alt="Grower's Facebook" />
            </a>
            <a href="https://www.instagram.com/grower_invest/" target="_blank">
              <img src="@/assets/icons/instagram-white.svg" alt="Grower's Instagram" />
            </a>
            <a href="https://twitter.com/Grower_invest" target="_blank">
              <img src="@/assets/icons/twitter-white.svg" alt="Grower's Twitter" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'Footer',
  methods: {
    frontDownload (type) {
      this.$gtag.event(`footer_download_${type}`)
      window.open(`https://growerinvest.com/static/${type}.pdf`, '_blank')
    }
  }
}
</script>
<style scoped>
.footer-outer {
  margin-top: -3rem;
  z-index: 1;
  position: relative;
  border-top: 67px solid transparent;
  border-image: url(../../assets/images/footer-grass.svg) 50 round;
}

.footer-wrapper {
  background-color: #649187;
  padding-bottom: 50px;
}

.footer-inner {
  display: flex;
  flex-direction: row;
  color: white;
  width: 80%;
  margin: auto;
  align-items: baseline;
}

.footer-inner > * {
  flex: 1 1 180px;
}

.payments-logos {
  padding-top: 30px;
  margin: auto;
}

.social-links-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.social-links-footer a {
  width: 60px;
}

.ul-footer, .ul-footer > li {
  list-style-image: unset;
}

li {
  cursor: pointer;
}

li:hover {
  font-weight: 600;
}

li a {
  color: white;
}

.menu-footer.padding-top div {
  display: none;
}

@media screen and (max-width: 768px) {
  .footer-inner > * {
    flex: 1 1 100px;
  }

  .menu-footer.padding-top {
    padding-top: 15px;
  }

  .menu-footer.padding-top div {
    text-align: center;
    padding-bottom: 5px;
  }
}

@media screen and (max-width: 428px) {
  .footer-inner {
    flex-direction: column;
    align-items: center;
  }

  .footer-inner > * {
    flex: 1 1 80px;
  }
}
</style>
