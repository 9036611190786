<template>
  <section class="main-section-section">
    <div class="main-section-wrapper">
      <article class="main-section-article">
        <span>GROWER</span>
        <h1 v-html="$t('h1_title')" class="main-section-text light-green-text-color" />
        <join-us-button />
      </article>
      <div class="main-section-phones">
        <img :src="mockupsUrl" class="main-section-image" rel="preload" alt="Grower Screens" />
      </div>
    </div>
    <img src="@/assets/icons/arrow-down.svg" @click="$emit('scroll-to', 'our_mission')" class="next-section" />
  </section>
</template>
<script>
import JoinUsButton from '@/components/JoinUsButton'

export default {
  name: 'MainSection',
  components: {
    JoinUsButton
  },
  mounted () {
    this.$bus.$on('language-changed', this.languageChanged)
    if (!localStorage.getItem('locale')) {
      let t = setInterval(() => {
        if (localStorage.getItem('locale')) {
          this.mockupsUrl = require(`@/assets/images/image-mockups-${this.langsMap[localStorage.getItem('locale')]}.svg`)
          clearInterval(t)
        }
      }, 50)
    } else {
      this.mockupsUrl = require(`@/assets/images/image-mockups-${this.langsMap[localStorage.getItem('locale')]}.svg`)
    }
  },
  data: () => ({
    langsMap: {
      'en-US': 'en',
      'uk-UA': 'uk'
    },
    mockupsUrl: require('@/assets/images/image-mockups-uk.svg')
  }),
  methods: {
    languageChanged (newLanguage) {
      this.mockupsUrl = require(`@/assets/images/image-mockups-${newLanguage.code}.svg`)
    }
  },
  beforeDestroy () {
    this.$bus.$off('language-changed', this.languageChanged)
  }
}
</script>
<style scoped>
.next-section {
  position: absolute;
  width: 60px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 3rem;
  cursor: pointer;
}

.main-section-wrapper {
  display: flex;
  width: 75%;
  margin: auto;
}

.main-section-article {
  width: 35%;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  padding-left: 1rem;
}

.countdown {
  background-color: #0BCA7C;
  text-align: center;
  color: #fff;
  border-radius: 50px;
  padding: 20px 25px;
  margin-bottom: 20px;
  max-width: 300px;
  width: 100%;
}

.countdown span {
  display: block;
  font-size: 20px;
}

.countdown .counter {
  font-weight: bold;
}

.main-section-article span {
  font-size: 64px;
  font-weight: 600;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: left;
  margin-bottom: 1rem;
  z-index: 1;
}

.main-section-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 36px;
  color: #649187;
}

.main-section-phones {
  width: 65%;
  background-image: url(../../assets/images/bg-intro-desktop.svg);
  background-size: cover;
  background-repeat: no-repeat;
  padding-left: 10rem;
}
.main-section-phones img {
  min-height: 790px;
}

@media screen and (max-width: 1280px) {
  .main-section-wrapper {
    width: 80%;
  }

  .main-section-phones {
    width: 75%;
  }
}

@media screen and (max-width: 1024px) {
  .main-section-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .main-section-wrapper {
    flex-direction: column;
  }

  .main-section-wrapper span {
    display: none;
  }

  .main-section-text {
    text-align: center;
  }

  .main-section-article, .main-section-phones {
    width: 100%;
    align-items: center;
  }

  .main-section-article {
    padding: 6rem 0 2rem 0;
  }

  .main-section-phones {
    padding: 0;
    text-align: center;
  }

  .next-section {
    display: none;
  }
}
</style>
