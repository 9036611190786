<template>
  <section class="section-our-mission">
    <div class="our-mission-wrapper">
      <div class="our-mission-outer">
        <div v-if="banners.length" class="offer-explanation-sliders">
          <div class="content">
            <h2 class="title-sliders">
              {{ $t('unique_offer') }}
            </h2>
            <swiper class="sliders" :slides-per-view="1" :options="sliderOptions">
              <swiper-slide v-for="banner in banners" :key="banner.id">
                <img class="slide-image" :src="banner.image_url" />
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="our-mission-inner">
          <div class="our-mission">
            <div class="image">
              <img src="@/assets/images/greenhouse.svg" alt="Grower Greenhouse" />
            </div>
            <div class="content">
              <h2 class="title-our-mission">
                {{ $t('our_mission') }}
              </h2>
              <p class="text-title-our-mission" v-html="$t('our_mission_text')" />
              <div class="more-details">
                <generic-button @click.native="$bus.$emit('details-click')" light>
                  {{ $t('more_details_modal_button') }}
                </generic-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="instructions-outer">
      <div class="instructions-title">
        <h2>
          {{ $t('instructions_title') }}
        </h2>
      </div>
      <div class="instructions-inner">
        <img src="@/assets/images/invest-man.svg" alt="Grower Invest Man" />
        <arrow-label>
          {{ $t('invest_call') }}
        </arrow-label>
        <img src="@/assets/images/invest-coin.svg" alt="Grower Invest Coin" />
        <arrow-label>
          {{ $t('grow_call') }}
        </arrow-label>
        <img src="@/assets/images/grow-plant.svg" alt="Grower Grow Plant" />
        <arrow-label>
          {{ $t('earn_call') }}
        </arrow-label>
        <img src="@/assets/images/earn-money.svg" alt="Grower Earn Money" />
      </div>
      <div class="instructions-final">
        <span>{{ $t('no_borders') }}</span>
        <span>{{ $t('no_limits') }}</span>
        <span>{{ $t('no_stress') }}</span>
      </div>
    </div>
    <div class="details-outer">
      <div class="details-inner">
        <generic-button @click.native="$bus.$emit('trade-details-click')">
          {{ $t('more_details_button') }}
        </generic-button>
      </div>
    </div>
  </section>
</template>
<script>
import request from '@/server/request'
import ArrowLabel from '@/components/Core/ArrowLabel'
import GenericButton from '@/components/Core/Form/GenericButton'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'OurMissionSection',
  components: {
    ArrowLabel,
    GenericButton,
    Swiper,
    SwiperSlide
  },
  mounted () {
    this.loadBannerItems()
  },
  data: () => ({
    banners: [],
    bannersLoading: false
  }),
  computed: {
    sliderOptions () {
      return {
        spaceBetween: 50,
        centeredSlides: true,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        }
      }
    }
  },
  methods: {
    loadBannerItems () {
      this.bannersLoading = true
      return request(
        { entity: 'landing.unique_offer_banners', action: 'list' },
        null,
        'GET'
      )
        .then(result => {
          this.banners = result.data
          return result.data
        })
        .finally(() => {
          this.bannersLoading = false
        })
    }
  }
}
</script>
<style scoped>
.section-our-mission {
  margin-top: -3rem;
}

.our-mission-outer {
  border-top: 67px solid transparent;
  border-image: url(../../assets/images/light-green-grass.svg) 50 round;
}

.swiper-container.sliders {
  width: 80%;
  margin: auto;
  border: 2px solid;
  border-radius: 15px;
  max-height: 450px;
}

img.slide-image {
  width: 100%;
}

.our-mission-inner, .offer-explanation-sliders {
  background-color: #0FD283;
  color: #FFFFFF;
  text-align: center;
}

.our-mission-inner h2, .offer-explanation-sliders h2 {
  color: #FFFFFF;
  padding-bottom: 20px;
}

.our-mission {
  display: flex;
  flex-direction: row;
  padding: 3rem 0;
  width: 90%;
  margin: auto;
}

.our-mission > .image {
  width: 50%;
  text-align: center;
}

.our-mission > .content {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.title-our-mission {
  font-size: 32px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0.05em;
  text-align: left;
}
.text-title-our-mission {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.05em;
  text-align: left;
  opacity: 0.7;
  flex: 1 1;
}

ul.text-title-our-mission {
  margin: 20px 0;
}

.more-details {
  text-align: right;
}

.more-details button {
  background: #FFFFFF !important;
  color: #0BCA7C !important;
  border: 2px solid #FFFFFF !important;
}

.more-details button:hover {
  color: #649187 !important;
  border: 2px solid #649187 !important;
}

.instructions-outer {
  text-align: center;
  border-top: 67px solid transparent;
  border-image: url(../../assets/images/mint-grass.svg) 50 round;
  margin-top: -3rem;
  padding-top: 100px;
  padding-bottom: 100px;
}

.details-outer {
  text-align: center;
  padding-bottom: 200px;
}

h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #000000;
  text-align: center;
}

.instructions-inner {
  display: inline-block;
  margin: 50px 0;
}

.instructions-inner * {
  display: inline-block;
}

.instructions-inner img {
  max-width: 215px;
  max-height: 255px;
}

.instructions-final {
  display: flex;
}

.instructions-final span {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.05em;
  opacity: 0.7;
  flex: 1 1;
}

@media screen and (max-width: 1024px) {
  .instructions-outer {
    padding-bottom: 50px;
  }

  .instructions-inner img {
    max-width: 150px;
    max-height: 180px;
  }

  .details-outer {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 768px) {
  .swiper-container.sliders {
    width: 100%;
  }

  .swiper-slide {
    width: 100%!important;
  }

  .our-mission {
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }

  .our-mission > .content {
    width: 90%;
  }

  .title-our-mission {
    text-align: center;
  }

  .text-title-our-mission {
    text-align: center;
  }

  ul.text-title-our-mission {
    width: 31%;
    margin: 20px auto;
    text-align: left;
  }

  .more-details {
    text-align: center;
    margin: 25px 0;
  }

  .instructions-outer {
    padding-bottom: 0;
  }

  .instructions-title {
    margin-bottom: 20px;
  }

  .instructions-inner {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 25%;
    align-items: center;
  }

  .instructions-final {
    flex-direction: column;
    padding: 20px 0;
  }

  .instructions-final span {
    padding: 20px 0;
  }
}

@media screen and (max-width: 428px) {
  ul.text-title-our-mission {
    width: 56%;
  }

  .instructions-inner {
    width: 40%;
  }

  .our-mission > .image {
    width: 100%;
  }
}
</style>
