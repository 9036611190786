export default {
  props: {
    theme: {
      type: String,
      default: null,
      validator (x) {
        return ['dark'].includes(x)
      }
    }
  },

  computed: {
    computedTheme () {
      if (this.theme) {
        return this.theme
      }

      return this.$gb.vuetimeline.theme
    }
  }
}
