<template>
  <modal :title="$t('signup_title')" @close="$emit('close')">
    <template v-if="step" #body>
      <div class="body-content">
        <component :is="step.component" :payload="step.data" ref="component" @validation-changed="updateStepValidation" />
      </div>
      <div v-if="step.name !== 'success'" class="actions">
        <generic-button @click.native="backStep" :disabled="!backStepAvailable">
          {{ $t('back') }}
        </generic-button>
        <generic-button @click.native="nextStep" :disabled="!step.valid">
          {{ !nextStepAvailable ? $t('submit') : $t('confirm') }}
        </generic-button>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Core/Modal'
import GenericButton from '@/components/Core/Form/GenericButton'
import request from '@/server/request'

const LoginDetailsForm = () => import(/* webpackPrefetch: true */ '@/components/JoinUsModal/LoginDetailsForm')
const UserInformationForm = () => import(/* webpackPrefetch: true */ '@/components/JoinUsModal/UserInformationForm')
const BuyingRequestCreating = () => import(/* webpackPrefetch: true */ '@/components/JoinUsModal/BuyingRequestCreating')
const Success = () => import(/* webpackPrefetch: true */ '@/components/JoinUsModal/Success')

export default {
  components: {
    Modal,
    GenericButton,
    LoginDetailsForm,
    UserInformationForm,
    BuyingRequestCreating,
    Success
  },
  data: () => ({
    steps: [
      {
        name: 'login-details',
        component: 'login-details-form',
        valid: false,
        data: {}
      },
      {
        name: 'user-information',
        component: 'user-information-form',
        valid: false,
        data: {}
      },
      {
        name: 'buying-request-creating',
        component: 'buying-request-creating',
        valid: false,
        data: {}
      },
      {
        name: 'success',
        component: 'success',
        valid: false,
        data: {}
      }
    ],
    step: null
  }),
  computed: {
    backStepAvailable () {
      return this.step && this.steps.indexOf(this.step) > 0
    },
    nextStepAvailable () {
      return this.step && this.step.valid && this.steps.indexOf(this.step) !== (this.steps.length - 1)
    }
  },
  mounted () {
    if (!this.step) {
      this.step = this.steps.find(s => s.name === 'login-details')
    }
  },
  methods: {
    registerUser () {
      const credentials = this.steps.find(s => s.name === 'login-details').data
      const personalInfo = this.steps.find(s => s.name === 'user-information').data
      let user = {
        ...credentials,
        ...personalInfo
      }
      return request({ entity: 'user', action: 'register' }, null, 'POST', user)
    },
    async uploadDocuments (token) {
      const documents = this.steps.find(s => s.name === 'user-documents-uploading').data
      let ids = Array.from(documents.documents)
      let payload = new FormData()
      ids.forEach((file, i) => {
        payload.append(`documents[${i}]`, file, file.name)
      })
      payload.append('type', 'id')
      await request({ entity: 'user.documents', action: 'upload' }, token, 'POST', payload, false, true)
      payload = new FormData()
      let itins = Array.from(documents.itin)
      itins.forEach((file, i) => {
        payload.append(`documents[${i}]`, file, file.name)
      })
      payload.append('type', 'itin')
      await request({ entity: 'user.documents', action: 'upload' }, token, 'POST', payload, false, true)
    },
    createBuyRequest (token) {
      const buyRequest = this.steps.find(s => s.name === 'buying-request-creating').data
      let payload = {
        product_id: buyRequest.product_id,
        qty: buyRequest.qty
      }
      return request({ entity: 'auction.buy_request', action: 'create' }, token, 'POST', payload)
    },
    updateStepValidation (valid) {
      this.step.valid = valid
    },
    backStep () {
      this.step.data = this.$refs.component.user
      const currStepIdx = this.steps.indexOf(this.step)
      if (!this.backStepAvailable) {
        return
      }
      this.step = this.steps[currStepIdx - 1]
      this.$gtag.event(`back_${this.step.name}`)
    },
    nextStep () {
      this.step.data = this.$refs.component.user
      const currStepIdx = this.steps.indexOf(this.step)
      if (currStepIdx === this.steps.length - 2) {
        this.submit()
        return
      }
      this.step = this.steps[currStepIdx + 1]
      this.$gtag.event(`next_${this.step.name}`)
    },
    async submit () {
      const credentials = await this.registerUser()
      if (credentials.token_type === 'Bearer' && credentials.access_token) {
        // await this.uploadDocuments(credentials.access_token)
        await this.createBuyRequest(credentials.access_token)
          .then(res => {
            if (res && res.data) {
              this.$gtag.event('purchase', {
                transaction_id: res.data.id,
                affiliation: 'Grower Landing',
                value: res.data.qty * 1.25,
                currency: 'USD',
                items: [
                  {
                    item_id: res.data.product.id,
                    item_name: res.data.product.name,
                    quantity: res.data.qty,
                    price: 1.25
                  }
                ]
              })
            }
          })
      }
      this.step = this.steps[this.steps.length - 1]
    }
  }
}
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  padding-bottom: 15px;
  button {
    min-width: 200px;
  }
  button:first-of-type {
    background-color: white;
    color: #009357;
    border: 2px solid #009357;
  }
  button:last-of-type {
    margin-left: auto;
  }

  @media screen and (max-width: 428px) {
    button {
      min-width: 150px;
    }
  }

  @media screen and (max-width: 360px) {
    button {
      min-width: 100px;
    }
  }
}
</style>
