<template>
  <div class="language-switcher">
    <div class="switcher" @click="showList = !showList" :class="{ active: showList }">
      <img src="@/assets/icons/globe.svg" />
      <span>{{ currentLanguage.short }}</span>
    </div>
    <div class="languages-list" :class="{ active: showList }">
      <ul>
        <li v-for="language in languages" @click="setLanguage(language.locale)"
            :class="{ selected: language.code === currentLanguage.code }"
            :key="language.code">
          {{ language.title }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LanguageSwitcher',
  data: () => ({
    languages: [
      { title: 'Українська', short: 'Укр', code: 'uk', locale: 'uk-UA' },
      { title: 'English', short: 'En', code: 'en', locale: 'en-US' }
    ],
    showList: false,
    localesMap: {
      en: 'en-US',
      uk: 'uk-UA'
    },
    currentLocale: 'uk-UA'
  }),
  computed: {
    currentLanguage () {
      return this.languages.find(l => l.locale === this.currentLocale) || this.languages.find(l => l.code === 'uk')
    }
  },
  created () {
    if (!localStorage.getItem('locale')) {
      if (['uk-UA', 'en-US'].includes(window.navigator.language)) {
        this.currentLocale = window.navigator.language
      } else if (/^ru\b/.test(navigator.language)) {
        // We've to keep current language for russians
      } else {
        this.currentLocale = 'en-US'
      }
    } else {
      this.currentLocale = localStorage.getItem('locale')
    }
    let urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('lang') && this.localesMap[urlParams.get('lang')]) {
      this.currentLocale = this.localesMap[urlParams.get('lang')]
    }
    this.setLanguage(this.currentLocale)
  },
  methods: {
    setLanguage (locale) {
      localStorage.setItem('locale', locale)
      this.currentLocale = locale
      this.$i18n.locale = locale
      this.$i18n.fallbackLocale = locale
      this.$bus.$emit('language-changed', this.currentLanguage)
    }
  }
}
</script>
<style scoped>
.language-switcher {
  margin: auto;
  padding: 0 5px;
  color: #649187;
  min-width: 90px;
  cursor: pointer;
  position: relative;
}

.switcher {
  padding: 3px 10px;
}

.switcher.active {
  background-color: #0BCA7C;
  color: #fff;
  border-radius: 15px;
}

.switcher span {
  padding-left: 5px;
}

.languages-list {
  display: none;
  position: absolute;
  background: white;
  border-radius: 5px;
  border: 1px solid #649187;
  padding: 10px 20px;
  right: 0;
  margin-top: 10px;
}

.languages-list::after {
  content: "";
  position: absolute;
  bottom: 100%;
  rotate: 180deg;
  right: 40px;
  border-width: 10px;
  border-style: solid;
  border-color: #649187 transparent transparent transparent;
}

.languages-list.active {
  display: block;
}

.languages-list li {
  padding: 10px 30px;
  border-bottom: 1px solid #649187;
  margin: 5px 0;
  text-align: center;
}

.languages-list li:last-of-type {
  border-bottom: none;
}

.languages-list .selected {
  color: #fff;
  background-color: #0BCA7C;
  border-radius: 5px;
  cursor: auto;
}

@media screen and (max-width: 996px) {
  .language-switcher .switcher span {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .language-switcher {
    min-width: 50px;
    order: 1;
    padding: unset;
  }

  .language-switcher .switcher {
    text-align: center;
  }

  .language-switcher .switcher img {
    width: 36px;
  }

  .languages-list {
    border-radius: unset;
    border: none;
    margin-top: 10px;
    width: 100%;
    position: fixed;
  }

  .languages-list::after {
    border: unset;
  }
}
</style>
<style>
@media screen and (max-width: 768px) {
  .join-us-icon {
    order: 2;
  }
}
</style>
