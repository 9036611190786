<template>
  <section class="section-contacts">
    <div class="contacts-outer">
      <div class="contacts-inner">
        <div class="contacts-box">
          <h2>
            {{ $t('contacts') }}
          </h2>
          <p>
            <a href="mailto:support@growerinvest.com">support@growerinvest.com</a><br />
            <a href="tel:+380-981-109-292">+380-981-109-292</a><br />
            <a href="tel:+380-991-109-292">+380-991-109-292</a><br />
            <a href="https://www.google.com/maps/place/Ternopil,+Ternopil+Oblast,+46003/@49.5483493,25.5626497,13z/data=!3m1!4b1!4m5!3m4!1s0x473036ad4b82ce75:0xc484a447edb154e8!8m2!3d49.553517!4d25.594767" target="_blank">м. Тернопіль</a><br />
          </p>
          <div class="social-links">
            <a href="https://www.facebook.com/grower_invest-107352865442627/" target="_blank">
              <img src="@/assets/icons/facebook-white.svg" alt="Grower's Facebook" />
            </a>
            <a href="https://www.instagram.com/grower_invest/" target="_blank">
              <img src="@/assets/icons/instagram-white.svg" alt="Grower's Instagram" />
            </a>
            <a href="https://twitter.com/Grower_invest" target="_blank">
              <img src="@/assets/icons/twitter-white.svg" alt="Grower's Twitter" />
            </a>
          </div>
          <div class="contact-us-button">
            <generic-button @click.native="$bus.$emit('contact-us-click')" light>
              {{ $t('contact_us') }}
            </generic-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import GenericButton from '@/components/Core/Form/GenericButton'

export default {
  name: 'ContactsSection',
  components: {
    GenericButton
  }
}
</script>
<style scoped>
.contacts-outer {
  background-image: url(../../assets/images/map.svg);
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 645px;
  height: 34rem;
  width: 100%;
}

.contacts-inner {
  background-color: #009357;
  color: white;
}

.contacts-inner .contacts-phone {
  padding-top: 65px;
  text-align: center;
}

.contacts-box {
  margin-left: 15rem;
  margin-top: -4rem;
  background: #0BCA7C;
  border-radius: 15px;
  width: 20rem;
  height: 35rem;
  position: absolute;
  display: flex;
  flex-direction: column;
}

h2 {
  margin: 53px auto 0;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  border-bottom: 2px solid #DAF0E9;
  width: 75%;
  padding-bottom: 35px;
}

p {
  width: 75%;
  margin: 20px auto 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 200%;
  letter-spacing: 0.05em;
  color: #DAF0E9;
}

p a {
  color: #DAF0E9;
}

p a:hover {
  text-decoration: underline;
}

.social-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 75%;
  margin: 120px auto 0;
}

.social-links a {
  width: 60px;
}

.contact-us-button {
  margin: 100px auto;
}

@media screen and (max-width: 2560px) {
  .contacts-box {
    margin-left: 20rem;
  }

  .social-links {
    margin: 90px auto 0;
  }

  .contact-us-button {
    margin: 80px auto;
  }
}

@media screen and (max-width: 1440px) {
  .contacts-box {
    margin-left: 15rem;
  }
}

@media screen and (max-width: 1024px) {
  .contacts-box {
    margin-left: 8rem;
  }

  .social-links {
    margin: 80px auto 0;
  }

  .contact-us-button {
    margin: 80px auto;
  }
}

@media screen and (max-width: 768px) {
  .contacts-box {
    margin-left: 15rem;
  }

  .social-links {
    margin: 70px auto 0;
  }

  .contact-us-button {
    margin: 60px auto;
  }
}

@media screen and (max-width: 428px) {
  .contacts-box {
    margin-left: 5rem;
  }

  .contacts-box p {
    font-size: 16px;
  }

  .social-links {
    margin: 50px auto 0;
  }

  .contact-us-button {
    margin: 50px auto;
  }
}

@media screen and (max-width: 375px) {
  .contacts-box {
    margin-left: 3rem;
  }
}

@media screen and (max-width: 320px) {
  .contacts-box {
    margin-left: 1rem;
  }
}
</style>
