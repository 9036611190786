<template>
  <modal :title="$t('more_details_modal_button')" @close="$emit('close')">
    <template #body>
      <div class="body-content" v-html="modalContent" />
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Core/Modal'

export default {
  components: {
    Modal
  },
  computed: {
    modalContent () {
      if (window.___SETTINGS___) {
        let setting = window.___SETTINGS___.find(s => s.path === 'landing/details/who_we_are')
        return setting ? setting.value : ''
      }
      return ''
    }
  }
}
</script>
