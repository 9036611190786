<template>
  <div class="relative base-input">
    <div class="relative">
      <input v-if="mask" class="py10 px10 w-100 border-box brdr-1 brdr-cl-primary h4 sans-serif"
             :class="{pr30: type === 'password', empty: value === '', error: getConditionsLength > 0}"
             :type="type === 'password' ? passType : type"
             :name="name"
             :autocomplete="autocomplete"
             :value="value"
             :autofocus="autofocus"
             :ref="name"
             :maxlength="maxlength"
             :max="max"
             :min="min"
             :disabled="disabled"
             v-mask="mask"
             @input="$emit('input', $event.target.value)"
             @blur="$emit('blur')"
             @keyup.enter="$emit('keyup.enter', $event.target.value)"
             @keyup="$emit('keyup', $event)"
             data-hj-whitelist
      >
      <input
        v-else
        class="py10 px10 w-100 border-box brdr-1 brdr-cl-primary h4 sans-serif"
        :class="{pr30: type === 'password', empty: value === '', error: getConditionsLength > 0}"
        :type="type === 'password' ? passType : type"
        :name="name"
        :autocomplete="autocomplete"
        :value="value"
        :autofocus="autofocus"
        :ref="name"
        :maxlength="maxlength"
        :max="max"
        :min="min"
        :disabled="disabled"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur')"
        @keyup.enter="$emit('keyup.enter', $event.target.value)"
        @keyup="$emit('keyup', $event)"
        data-hj-whitelist
      >
      <label>{{ placeholder }}</label>
    </div>
    <button
      v-if="iconActive"
      type="button"
      class="
        icon-button icon material-icons absolute brdr-none no-outline
        p0 bg-cl-transparent cl-brdr-secondary pointer
      "
      @click="togglePassType()"
      :aria-label="$t('toggle_password_visibility')"
      :title="$t('toggle_password_visibility')"
    >
      <mdicon :name="icon" />
    </button>
    <validation-messages v-if="validations" :validations="validations" class="mb5" />
  </div>
</template>

<script>
import ValidationMessages from '@/components/Core/Form/ValidationMessages'

export default {
  name: 'BaseInput',
  components: {
    ValidationMessages
  },
  data () {
    return {
      passType: 'password',
      iconActive: false,
      icon: 'eye-off'
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    maxlength: {
      type: Number,
      default: 524288
    },
    max: {
      type: Number,
      default: 524288
    },
    min: {
      type: Number,
      default: 524288
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    },
    mask: {
      type: Object,
      required: false
    }
  },
  methods: {
    togglePassType () {
      if (this.passType === 'password') {
        this.passType = 'text'
        this.icon = 'eye'
      } else {
        this.passType = 'password'
        this.icon = 'eye-off'
      }
    },
    // setFocus sets focus on a field which has a value of 'ref' tag equal to fieldName
    setFocus (fieldName) {
      if (this.name === fieldName) {
        this.$refs[this.name].focus()
      }
    }
  },
  computed: {
    getConditions () {
      return this.validations.filter(cond => {
        return cond.condition === true
      })
    },
    getConditionsLength () {
      return this.getConditions.length
    }
  },
  created () {
    if (this.type === 'password') {
      this.iconActive = true
    }
  },
  mounted () {
    if (this.focus) {
      this.$refs[this.name].focus()
    }
  }
}
</script>
<style scoped>
input {
  color: #649187;
}

.icon-button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.base-input svg {
  fill: #BDE5DD;
}
</style>
